import {Component} from "react";
import { navigate } from "gatsby";
import {withTranslation} from "react-i18next";
import config from "../../content/meta/config";

class Index extends Component {

  componentDidMount() {
    const {i18n} = this.props;

    if (i18n && i18n.language) {
      navigate(`/${i18n.language.substring(0, 2)}`);
    } else {
      navigate(`/${config.defaultLanguage}`);
    }
  }

  render() {
    return "";
  }
}

export default withTranslation()(Index);
